import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { buttonClasses } from "@mui/material/Button";
import { buttonGroupClasses } from "@mui/material/ButtonGroup";
import { checkboxClasses } from "@mui/material/Checkbox";
import { chipClasses } from "@mui/material/Chip";
import { grey } from "@mui/material/colors";
import { filledInputClasses } from "@mui/material/FilledInput";
import { formControlLabelClasses } from "@mui/material/FormControlLabel";
import { formLabelClasses } from "@mui/material/FormLabel";
import { inputClasses } from "@mui/material/Input";
import { inputBaseClasses } from "@mui/material/InputBase";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { menuItemClasses } from "@mui/material/MenuItem";
import { selectClasses } from "@mui/material/Select";
import {
  alpha,
  Components,
  createTheme as createMuiTheme,
  Theme,
  TypographyVariants,
} from "@mui/material/styles";
import { tabClasses } from "@mui/material/Tab";
import deepmerge from "@mui/utils/deepmerge";
import { textFieldClassesExtended } from "@reservauto/react-shared/ui/field/TextField";
import themeSizes from "@reservauto/react-shared/ui/theme/themeSizes";
import React from "react";
import { ReactComponent as CheckboxSelectedIcon } from "../shared/icons/checkbox-selected.svg";
import { ReactComponent as CheckboxIcon } from "../shared/icons/checkbox.svg";
import "./moduleAugmentation";

export const colorClasses = {
  alt: "color-alt",
  hint: "color-hint",
  map: "color-map",
  primary: "color-primary",
  secondary: "color-secondary",
};

const fontFamily = "Roboto, Helvetica, Arial, sans-serif";
const fontSize = 16;

export default function createTheme(language: unknown | undefined): Theme {
  const theme = createMuiTheme(
    {
      palette: {
        action: {
          hover: "#F8F9F9",
          icon: "#66676B",
        },
        alt: {
          dark: "#DADCE0",
          main: "#F1F3F4",
        },
        background: {
          default: "white",
          light: "rgba(241, 243, 244, 0.32)",
          processing: "rgba(255, 255, 255, 0.7)",
        },
        completed: {
          main: "#2AAB47",
        },
        divider: "#f1f3f4",
        dividerExtended: {
          dark: "#c2cbce",
          medium: "#E0E0E0",
        },
        error: {
          light: "rgba(235, 77, 75, 0.15)",
          main: "#eb4d4b",
        },
        interventionReviewStatus: {
          blue: "#51C2ED",
          green: "#5AD75A",
          pink: "#FFC5C5",
          yellow: "#F3FF9F",
        },
        map: {
          dark: "#F15429",
          light: "#228BE6",
          main: "#84bd00",
          returnFlex: "#049C5F",
          userMarker: "#4285f4",
        },
        primary: {
          dark: "rgb(7, 49, 158)",
          light: "#1876F7",
          main: "#0a46e3",
        },
        secondary: {
          dark: "#0A47E3",
          light: "#E9F3FF",
          main: "#2071f9",
        },
        success: {
          light: "rgba(105, 191, 43, 0.14)",
          main: "#368300",
        },
        text: {
          alt: "#212121",
          hint: "#66676B",
          primary: "#3d3d3d",
          secondary: "#979797",
        },
        urgent: {
          main: "#F7241E",
        },
        warning: {
          light: "#FFE3B3",
          main: "#CAA10D",
        },
      },
      pxToRem: (size: number) => `${size / fontSize}rem`,
      spacing: 5,
      zIndex: {
        tableStickyHeader: 2,
      },
    },
    language ?? {},
  );

  const components: Components = {
    MuiAppBar: {
      defaultProps: {
        color: "default",
        position: "sticky",
      },
      styleOverrides: {
        colorDefault: {
          backgroundColor: "#FFFFFF",
          boxShadow: "0 -1px 7px 0 rgba(0, 0, 0, 0.2)",
        },
        root: {
          "& .logo": {
            "& > svg": {
              display: "block",
              width: 160,
            },
            marginRight: 34,

            [theme.breakpoints.down("md")]: {
              marginRight: 24,
            },
          },
          alignItems: "center",
          flexDirection: "row",
          height: themeSizes.topBarHeight,
          justifyContent: "space-between",
          padding: "0 20px",
          zIndex: theme.zIndex.drawer + 1,

          [theme.breakpoints.down("sm")]: {
            padding: "0 16px",
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMoreIcon />,
      },
      styleOverrides: {
        endAdornment: {
          [`& .${autocompleteClasses.popupIndicator}`]: {
            verticalAlign: "top",
          },
          "& svg": {
            color: theme.palette.alt.dark,
            fontSize: 24,
          },
        },
        groupLabel: {
          color: theme.palette.secondary.dark,
          fontWeight: "bold",
          lineHeight: theme.pxToRem(29),
          paddingLeft: 10,
          paddingRight: 10,
        },
        groupUl: {
          [`& .${autocompleteClasses.option}`]: {
            paddingLeft: 20,
          },
          margin: 0,
        },
        input: ({ ownerState }) => ({
          "&::-webkit-input-placeholder": {
            color: theme.palette.text.secondary,
            fontSize: theme.pxToRem(14),
            opacity: 1,
          },
          fontSize: theme.pxToRem(14),
          fontWeight: ownerState.multiple ? "normal" : 500,
          lineHeight: theme.pxToRem(14),
        }),
        inputRoot: {
          [`&.${inputClasses.underline}`]: {
            paddingBottom: 11,
          },
        },
        listbox: {
          [`& .${autocompleteClasses.option}`]: {
            [`&[aria-selected="true"], &[aria-selected="true"].${autocompleteClasses.focused}`]:
              {
                backgroundColor: theme.palette.secondary.light,
              },

            color: theme.palette.text.alt,
            fontSize: theme.pxToRem(14),
            minHeight: 0,
            padding: "5px 10px",
          },
          padding: "7px 0",
        },
        paper: {
          boxShadow:
            "0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 3px 16px 0 rgba(0, 0, 0, 0.14)",
        },
        root: ({ ownerState }) => ({
          [`&.${autocompleteClasses.hasPopupIcon} .${filledInputClasses.root}`]:
            {
              paddingRight: 32,
            },
          [`& label + div.${filledInputClasses.root}`]: {
            [`&.${inputBaseClasses.sizeSmall}`]: {
              [`& > input.${filledInputClasses.input}`]: {
                paddingBottom: 3,
                paddingLeft: 6,
                paddingTop: 0,
              },
              paddingTop: 18,
            },
            [`& > input.${filledInputClasses.input}`]: {
              paddingBottom: 10,
              paddingLeft: 6,
            },
            paddingTop: 19,
          },
          [`& .${filledInputClasses.root}`]: {
            [`&.${inputBaseClasses.sizeSmall}`]: {
              [`& .${autocompleteClasses.endAdornment}`]: {
                right: 3,
              },
              padding: ownerState.multiple ? "7px 12px" : undefined,
            },
            [`& .${autocompleteClasses.endAdornment}`]: {
              display: "flex",
              right: 13,
            },
            padding: ownerState.multiple ? "7px 12px" : undefined,
          },
          [`& .${inputBaseClasses.disabled} .${autocompleteClasses.endAdornment}`]:
            {
              display: "none",
            },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained",
      },
      styleOverrides: {
        outlined: {
          "&:hover": {
            backgroundColor: theme.palette.divider,
          },
          backgroundColor: "white",
        },
        root: {
          "&:hover": {
            boxShadow: "none",
          },
          boxShadow: "none",
          fontSize: theme.pxToRem(16),
          fontWeight: "bold",
          lineHeight: theme.pxToRem(16),
          padding: "12px 26px",
          textTransform: "none",
        },
        sizeLarge: {
          "&.short": {
            paddingLeft: 16,
            paddingRight: 16,
          },
          fontSize: theme.pxToRem(16),
          padding: "18px 42px",
        },
        sizeSmall: {
          fontSize: theme.pxToRem(14),
          padding: "8px 20px",
        },
      },
      variants: [
        {
          props: { color: "secondary", variant: "containedDark" },
          style: {
            "&:hover": {
              backgroundColor: alpha(theme.palette.secondary.dark, 0.8),
            },
            backgroundColor: theme.palette.secondary.dark,
            color: "white",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "elevated" },
          style: {
            [`&.${buttonGroupClasses.grouped}`]: {
              "&:hover, &:focus": {
                boxShadow: "none",
              },
              boxShadow: "none",
              minWidth: 0,
            },
            "&:hover, &:focus": {
              backgroundColor: theme.palette.action.hover,
              boxShadow:
                "0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.20)",
            },
            backgroundColor: theme.palette.common.white,
            borderRadius: 6,
            boxShadow:
              "0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.20)",
            color: theme.palette.action.icon,
            minWidth: 0,

            [theme.breakpoints.down("sm")]: {
              "& > svg": {
                fontSize: 22,
              },
              padding: 4,
            },
            [theme.breakpoints.up("sm")]: {
              "& > svg": {
                fontSize: 24,
              },
              padding: 8,
            },
          },
        },
        {
          props: { variant: "rounded" },
          style: {
            [`& .${buttonClasses.startIcon}`]: {
              marginRight: 5,
            },
            backgroundColor: "#FFFFFF",
            border: `1px solid ${theme.palette.dividerExtended.medium}`,
            borderRadius: 17,
            color: theme.palette.text.alt,
            fontSize: theme.pxToRem(14),
            fontWeight: "normal",
            minHeight: 32,
            padding: "5px 16px",

            [theme.breakpoints.down("sm")]: {
              minWidth: 0,
            },
          },
        },
      ],
    },
    MuiButtonGroup: {
      variants: [
        {
          props: { variant: "elevated" },
          style: {
            [`& .${buttonGroupClasses.grouped}:not(:last-of-type):after`]: {
              borderBottom: `1px solid ${theme.palette.divider}`,
              bottom: 0,
              content: '""',
              left: 10,
              position: "absolute",
              right: 10,
            },
            borderRadius: 6,
            boxShadow:
              "0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.20)",
          },
        },
      ],
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckboxSelectedIcon />,
        color: "secondary",
        icon: <CheckboxIcon />,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          "& > svg": {
            height: ownerState.size === "small" ? 14 : undefined,
            width: ownerState.size === "small" ? 14 : undefined,
          },
          color:
            !ownerState.checked && !ownerState.disabled
              ? theme.palette.text.alt
              : undefined,
          marginLeft:
            ownerState.edge === "start"
              ? -themeSizes.checkboxPaddingBySize[ownerState.size ?? "medium"]
              : undefined,
          marginRight:
            ownerState.edge === "end"
              ? -themeSizes.checkboxPaddingBySize[ownerState.size ?? "medium"]
              : undefined,
          padding:
            themeSizes.checkboxPaddingBySize[ownerState.size ?? "medium"],
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.palette.primary.main,
        },
        root: {
          "&.error": {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.dark,
          },
          "&.success": {
            backgroundColor: theme.palette.success.light,
            color: theme.palette.success.dark,
          },
          "&.warning": {
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.warning.dark,
          },
          backgroundColor: theme.palette.divider,
          fontSize: theme.pxToRem(16),
          minWidth: 138,
        },
      },
      variants: [
        {
          props: { variant: "elevated" },
          style: {
            [`&.${chipClasses.colorSecondary}`]: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
            },
            "&:hover, &:focus": {
              backgroundColor: theme.palette.secondary.light,
            },
            backgroundColor: theme.palette.common.white,
            boxShadow:
              "0 1px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.07)",
            color: theme.palette.text.alt,
            fontWeight: 500,
          },
        },
      ],
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "#root": {
          flex: 1,
          minHeight: 0,
        },
        [`.${colorClasses.alt}`]: {
          color: theme.palette.text.alt,
        },
        [`.${colorClasses.hint}`]: {
          color: theme.palette.text.hint,
        },
        [`.${colorClasses.map}`]: {
          color: theme.palette.map.main,
        },
        [`.${colorClasses.primary}`]: {
          color: theme.palette.primary.main,
        },
        [`.${colorClasses.secondary}`]: {
          color: theme.palette.text.secondary,
        },
        "@font-face": {
          fontFamily: fontFamily,
        },
        body: {
          "& > form": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
          },
          "&::-webkit-scrollbar, .custom-scrollbar::-webkit-scrollbar": {
            height: themeSizes.customScrollbarHeight,
            width: themeSizes.customScrollbarHeight,
          },
          "&::-webkit-scrollbar-thumb, .custom-scrollbar::-webkit-scrollbar-thumb":
            {
              "&:hover": {
                borderColor: theme.palette.dividerExtended.medium,
              },
              background: theme.palette.dividerExtended.medium,
              backgroundClip: "padding-box",
              border: "1px solid transparent",
              borderRadius: 4,
            },
          ".no-wrap": {
            whiteSpace: "nowrap",
          },
          color: theme.palette.text.primary,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          fontWeight: "normal",
          minHeight: 0,
        },
        html: {
          "& .custom-scrollbar": {
            scrollbarWidth: "thin",
          },
          display: "flex",
          flexDirection: "column",
          height: "100%",
          scrollbarColor: `${theme.palette.dividerExtended.medium} transparent`,
          scrollbarWidth: "thin",
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        slideTransition: {
          minHeight: 230,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
          boxShadow:
            "0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.2)",
          minWidth: 568,

          [theme.breakpoints.down("sm")]: {
            borderRadius: 0,
          },
          [theme.breakpoints.down("md")]: {
            minWidth: "calc(100% - 32px)",
          },
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        disableSpacing: true,
      },
      styleOverrides: {
        root: {
          "& > button": {
            "&:last-child": {
              marginRight: 0,
            },
            "&:not(:first-child)": {
              marginLeft: theme.spacing(3),
            },
            marginBottom: 0,
            minWidth: 120,
            textTransform: "none",

            [theme.breakpoints.down("sm")]: {
              "&:not(:first-child)": {
                marginBottom: theme.spacing(3),
                marginLeft: 0,
              },
              minWidth: 0,
            },
          },
          boxShadow: "0 -2px 13px 0 rgba(0, 0, 0, 0.06)",
          padding: "15px 40px",

          [theme.breakpoints.down("sm")]: {
            alignItems: "stretch",
            boxShadow: "none",
            flexDirection: "column-reverse",
            padding: "10px 16px 16px 16px",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          "& hr.left": {
            marginLeft: -40,

            [theme.breakpoints.down("sm")]: {
              marginLeft: -16,
            },
          },
          "& hr.right": {
            marginRight: -40,

            [theme.breakpoints.down("sm")]: {
              marginRight: -16,
            },
          },
          "&.medium": {
            paddingBottom: 40,
          },
          "&.short": {
            paddingBottom: 27,
          },
          "&:first-child": {
            paddingTop: 30,

            [theme.breakpoints.down("sm")]: {
              paddingTop: 16,
            },
          },
          padding: "0 40px 50px 40px",

          [theme.breakpoints.down("sm")]: {
            padding: "0 16px 30px 16px",
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: theme.palette.text.alt,
          marginBottom: 0,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "&.bottom-border": {
            borderBottom: `1px solid ${theme.palette.divider}`,
            marginBottom: 20,
            paddingBottom: 22,

            [theme.breakpoints.down("sm")]: {
              paddingBottom: 16,
            },
          },
          "&.tall": {
            paddingBottom: 30,
          },
          fontSize: theme.pxToRem(21),
          fontWeight: "bold",
          lineHeight: theme.pxToRem(21),
          padding: "30px 40px 16px 40px",

          [theme.breakpoints.down("sm")]: {
            padding: "16px 16px 10px 16px",
          },
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: "thick" },
          style: {
            borderBottomWidth: 2,
            borderColor: theme.palette.text.primary,
          },
        },
      ],
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          height: 45,
          width: 45,
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        autoComplete: "off",
        disableUnderline: true,
      },
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: `${theme.palette.text.primary} !important`,
          },
          "&:-webkit-autofill::first-line": {
            fontFamily: fontFamily,
            fontSize: theme.pxToRem(16),
          },
          fontWeight: "bold",
          "label + div > &": {
            paddingBottom: 10,
            paddingTop: 26,
          },
          paddingBottom: 6,
          paddingLeft: 14,
          paddingTop: 6,
        },
        root: {
          [`&.${filledInputClasses.disabled}`]: {
            [`&:hover`]: {
              backgroundColor: "#F5F6FA",
            },
            [`& .${filledInputClasses.input}`]: {
              cursor: "not-allowed",
              WebkitTextFillColor: theme.palette.text.primary,
            },
            backgroundColor: "#F5F6FA",
          },
          [`&:hover, &.${filledInputClasses.focused}`]: {
            backgroundColor: "white",
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: theme.palette.error.main,
          },

          backgroundColor: "white",
          border: `1px solid ${theme.palette.dividerExtended.medium}`,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        },
        sizeSmall: {
          [`& > .${filledInputClasses.input}`]: {
            fontSize: theme.pxToRem(14),
            fontWeight: 500,
            lineHeight: theme.pxToRem(14),
          },
          [`label + & > .${filledInputClasses.input}`]: {
            [`&.${selectClasses.select}`]: {
              paddingBottom: 5,
              paddingTop: 19,
            },
            paddingBottom: 4,
            paddingTop: 18,
          },
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          "&.outlined": {
            [`& .${checkboxClasses.root}`]: {
              padding: 7,
            },
            [`& .${formControlLabelClasses.label}`]: {
              color: ownerState.checked
                ? theme.palette.secondary.main
                : undefined,
              fontSize: theme.pxToRem(14),
            },
            border: `1px solid ${theme.palette.dividerExtended.medium}`,
            borderRadius: 20,
            marginBottom: 10,
            marginLeft: 0,
            marginRight: 10,
            paddingLeft: 4.5,
            paddingRight: 10,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: theme.pxToRem(12),
          margin: "2px 0 0 0",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          [`&.${formLabelClasses.focused}`]: {
            color: theme.palette.text.secondary,
          },
          fontSize: theme.pxToRem(16),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.contained": {
            "&:hover": {
              backgroundColor: grey.A100,
            },
            backgroundColor: theme.palette.divider,
          },
          "&.elevated": {
            "& svg": {
              fontSize: "inherit",
            },
            "&:hover": {
              background: theme.palette.action.hover,
            },
            background: "#FFFFFF",
            boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.25)",
            color: theme.palette.text.primary,
            padding: 0,
          },
        },
        sizeSmall: {
          height: 38,
          width: 38,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          "& svg": {
            color: theme.palette.action.icon,
            fontSize: 18,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: 16,
        },
        root: {
          [`&.${inputClasses.underline}`]: {
            "&:after": {
              borderBottomWidth: 1,
            },
            "&:before": {
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
            [`&:hover:not(.${inputClasses.disabled}):before`]: {
              borderBottom: `1px solid ${theme.palette.secondary.dark}`,
            },
          },
          fontSize: theme.pxToRem(16),
          lineHeight: theme.pxToRem(16),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          [`&.${inputLabelClasses.sizeSmall}`]: {
            [`&.${inputLabelClasses.shrink}`]: {
              [`&:has(+ .${inputBaseClasses.root} .${selectClasses.icon}), &:has( + .${inputBaseClasses.root} .${autocompleteClasses.endAdornment})`]:
                {
                  [`&:has(+ .${inputBaseClasses.root} .${autocompleteClasses.clearIndicator})`]:
                    {
                      maxWidth: "calc(117% - 79px)",
                    },
                  maxWidth: "calc(117% - 47px)",
                },
              maxWidth: "calc(117% - 30px)",
              transform: "translate(15px, 4px) scale(0.85)",
            },
            [`&:has(+ .${inputBaseClasses.root} .${selectClasses.icon}), &:has( + .${inputBaseClasses.root} .${autocompleteClasses.endAdornment})`]:
              {
                maxWidth: "calc(100% - 47px)",
              },

            fontSize: theme.pxToRem(14),
            lineHeight: theme.pxToRem(21),
            transform: "translate(15px, 10px) scale(1)",
          },
          [`&.${inputLabelClasses.shrink}`]: {
            [`&:has(+ .${inputBaseClasses.root} .${selectClasses.icon}), &:has( + .${inputBaseClasses.root} .${autocompleteClasses.endAdornment})`]:
              {
                maxWidth: "calc(133% - 47px)",
              },
            transform: "translate(15px, 8px) scale(0.75)",
          },
          [`&:has(+ .${inputBaseClasses.root} .${selectClasses.icon}), &:has( + .${inputBaseClasses.root} .${autocompleteClasses.endAdornment})`]:
            {
              maxWidth: "calc(100% - 52px)",
            },

          transform: "translate(15px, 16px) scale(1)",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: "secondary",
        underline: "none",
      },
      styleOverrides: {
        root: {
          "&:focus": {
            color: theme.palette.secondary.dark,
          },
          "&:hover": {
            color: theme.palette.secondary.dark,
          },
          cursor: "pointer",
          outline: "none",
        },
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          "& + svg": {
            color: theme.palette.text.alt,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          paddingBottom: 5,
          paddingTop: 5,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          [`&.${menuItemClasses.selected}`]: {
            "&:hover, &:focus": {
              backgroundColor: theme.palette.action.hover,
            },
            backgroundColor: theme.palette.action.hover,
            fontWeight: 500,
          },
          fontSize: theme.pxToRem(14),
          minHeight: 0,
          paddingBottom: 7.5,
          paddingTop: 7.5,
        },
      },
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          maxHeight: 324,
        },
      },
    },
    MuiMultiSectionDigitalClockSection: {
      defaultProps: {
        className: "custom-scrollbar",
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          paddingBottom: 5,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          "& svg": {
            fontSize:
              ownerState.size === "small" ? theme.pxToRem(17) : undefined,
          },
          marginLeft:
            ownerState.edge === "start" && ownerState.size === "small"
              ? -5
              : undefined,
          marginRight:
            ownerState.edge === "end" && ownerState.size === "small"
              ? -5
              : undefined,
          padding: ownerState.size === "small" ? 4 : undefined,
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
      styleOverrides: {
        icon: {
          color: theme.palette.alt.dark,
          fontSize: 24,
          top: "calc(50% - 12px)",
        },
        iconFilled: {
          [`.${inputBaseClasses.sizeSmall} &`]: {
            right: 3,
          },
          right: 13,
        },
        select: {
          minHeight: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: theme.palette.text.primary,
          },
          "&:last-child": {
            marginRight: 0,
          },
          [`&.${tabClasses.selected}`]: {
            color: theme.palette.text.primary,
          },
          fontSize: theme.pxToRem(16),
          fontWeight: "bold",
          marginRight: 100,
          minWidth: 160,
          textTransform: "none",

          [theme.breakpoints.down("lg")]: {
            marginRight: 50,
          },
          [theme.breakpoints.down("md")]: {
            marginRight: 25,
          },
        },
        textColorInherit: {
          "&.Mui-selected": {
            color: theme.palette.text.primary,
          },
          color: theme.palette.text.secondary,
          opacity: 1,
        },
      },
    },
    MuiTable: {
      defaultProps: {
        stickyHeader: true,
      },
      styleOverrides: {
        root: {
          minWidth: "100%",
          tableLayout: "fixed",
          whiteSpace: "nowrap",
          width: "auto",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "bold",
          padding: "25px 40px",
        },
        root: {
          "& .error": {
            "&:first-child": {
              marginTop: 0,
            },
            "&:last-child": {
              marginBottom: 0,
            },

            color: theme.palette.error.main,
            margin: "5px 0",
            overflow: "hidden",
          },
          "& input": {
            fontWeight: 500,
          },

          borderBottomColor: theme.palette.divider,
          fontSize: theme.pxToRem(16),
          fontWeight: "inherit",
          height: 60,
          padding: `14px ${themeSizes.tableCellHorizontalPadding}px`,
        },
        stickyHeader: {
          zIndex: theme.zIndex.tableStickyHeader,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderTop: "2px solid #3d3d3d",
          marginTop: 35,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          fontWeight: "bold",
        },
        selectRoot: {
          fontWeight: "bold",
          marginRight: 75,
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        hover: true,
      },
      styleOverrides: {
        head: {
          "&:hover": {
            backgroundColor: "transparent !important",
          },
        },
        root: {
          "&.error": {
            "&:hover": {
              backgroundColor: theme.palette.error.light,
            },
            backgroundColor: theme.palette.error.light,
          },
          "&.expandable": {
            [`& .${chipClasses.root}`]: {
              cursor: "pointer",
            },
            cursor: "pointer",
          },
          "&.summary": {
            "& > td": {
              height: 70,
            },
            "&:hover": {
              backgroundColor: theme.palette.divider,
            },
            backgroundColor: theme.palette.divider,
            fontWeight: "bold",
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: "#000000 !important",
          left: -27,
          opacity: 1,
          position: "absolute",
        },
        root: {
          "&:hover": {
            "& > svg": {
              color: `${theme.palette.primary.main} !important`,
              opacity: "1 !important",
            },
            color: `${theme.palette.primary.main} !important`,
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        allowScrollButtonsMobile: true,
        indicatorColor: "primary",
        scrollButtons: "auto",
        variant: "scrollable",
      },
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${theme.palette.divider}`,
          marginBottom: 45,
          paddingLeft: 73,

          [theme.breakpoints.down("lg")]: {
            paddingLeft: 43,
          },
          [theme.breakpoints.down("md")]: {
            paddingLeft: 23,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        size: "small",
        variant: "filled",
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          [`& .${textFieldClassesExtended.startIconLabelFilled}`]: {
            marginLeft: ownerState.size === "medium" ? 33 : 23,
          },
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: "bottom-start",
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          borderRadius: 6,
          fontSize: theme.pxToRem(12),
          fontWeight: "normal",
          lineHeight: theme.pxToRem(14),
          padding: "7px 8px",
        },
      },
    },
  };

  const typography: Partial<TypographyVariants> = {
    body1: {
      fontSize: theme.pxToRem(16),
      lineHeight: theme.pxToRem(20),

      [theme.breakpoints.down("sm")]: {
        fontSize: theme.pxToRem(14),
        lineHeight: theme.pxToRem(16),
      },
    },
    caption: {
      color: theme.palette.action.icon,
      fontSize: theme.pxToRem(16),
      fontWeight: 300,
      lineHeight: theme.pxToRem(20),
    },
    fontFamily: fontFamily,
    fontSize: fontSize,
    h1: {
      fontSize: theme.pxToRem(27),
      fontWeight: "bold",
      lineHeight: theme.pxToRem(32),
    },
    h2: {
      fontSize: theme.pxToRem(20),
      fontWeight: "bold",
      lineHeight: theme.pxToRem(24),
    },
    h3: {
      fontSize: theme.pxToRem(20),
      fontWeight: "normal",
      lineHeight: theme.pxToRem(24),
    },
    h4: {
      color: theme.palette.text.alt,
      fontSize: theme.pxToRem(18),
      fontWeight: "bold",
      lineHeight: theme.pxToRem(20),
    },
    h5: {
      fontSize: theme.pxToRem(14),
      fontWeight: "bold",
      lineHeight: theme.pxToRem(16),
      marginBottom: 5,
    },
    h6: {
      color: theme.palette.text.hint,
      fontSize: theme.pxToRem(14),
      fontWeight: "normal",
      lineHeight: theme.pxToRem(16),
    },
    hint: {
      color: theme.palette.text.hint,
      fontSize: theme.pxToRem(12),
      lineHeight: theme.pxToRem(14),
    },
    overline: {
      fontSize: theme.pxToRem(37),
      fontWeight: "bold",
      lineHeight: theme.pxToRem(43),
      textTransform: "none",
    },
    subtitle1: {
      fontSize: theme.pxToRem(14),
      fontWeight: 500,
      lineHeight: theme.pxToRem(16),
    },
    subtitle2: {
      fontSize: theme.pxToRem(16),
      fontWeight: "bold",
      lineHeight: theme.pxToRem(19),
    },
  };

  theme.components = deepmerge(theme.components, components);
  theme.typography = deepmerge(theme.typography, typography);

  return theme;
}
